import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'StandaloneBatteryForm';

export default defineMessages({
  heading: {
    id: `${I18N_NAMESPACE}.heading`,
    defaultMessage: 'Interested? Let us know!',
  },
  description: {
    id: `${I18N_NAMESPACE}.description`,
    defaultMessage:
      'Fill in this form and one of our experts will reach out to you. Together we ensure you get access to smarter solar energy consumption.',
  },
  hasPVSystem: {
    id: `${I18N_NAMESPACE}.hasPVSystem`,
    defaultMessage: 'I already have a PV system.',
  },
  howBigIsPVSystem: {
    id: `${I18N_NAMESPACE}.howBigIsPVSystem`,
    defaultMessage: 'How big is your PV system? (kWp)',
  },
  thankYouMessage: {
    id: `${I18N_NAMESPACE}.thankYouMessage`,
    defaultMessage: 'Thank you! We will contact you shortly.',
  },
  errorHasOccured: {
    id: `${I18N_NAMESPACE}.errorHasOccured`,
    defaultMessage: 'An unexpected error has occured. Please try again later.',
  },
});
