import { Sanity$HeatPumpForm } from '@otovo/shared/types/sanityTypes';
import Image from 'next/image';
import { useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import SectionWrapper from '../../../SectionWrapper';
import ConfirmationScreen from '../ConfirmationScreen';
import m from '../messages';
import HeatPumpsForm from './HeatPumpsForm';
import heatPumpsMessages from './messages';

type Props = {
  form: Sanity$HeatPumpForm;
};

const HeatPumpsFormSection = ({ form }: Props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorHasOccured, setErrorHasOccured] = useState(false);
  const intl = useIntl();

  const { formId } = form;

  return (
    <SectionWrapper>
      <div className="col-span-6 flex flex-col items-center justify-center md:col-span-6 md:flex-row">
        <div className="h-full w-full">
          <h2 className="text-2xl font-medium">
            <T {...heatPumpsMessages.heading} />
          </h2>
          <p className="mx-auto mb-6 text-base">
            <T {...heatPumpsMessages.description} />
          </p>
          {formSubmitted || errorHasOccured ? (
            <ConfirmationScreen
              errorHasOccured={errorHasOccured}
              thankYouMessage={intl.formatMessage(m.thankYouMessage)}
              errorMessage={intl.formatMessage(m.errorHasOccured)}
            />
          ) : (
            <HeatPumpsForm
              formId={formId}
              updateFormSubmitted={setFormSubmitted}
              updateErrorHasOccured={setErrorHasOccured}
            />
          )}
        </div>
      </div>
      <div className="col-span-2" />
      <div className="relative col-span-4 hidden h-auto md:block">
        <Image
          src="/img/heatPump.png"
          alt="Talk to us"
          fill
          className="object-contain"
        />
      </div>
    </SectionWrapper>
  );
};

export default HeatPumpsFormSection;
