import {
  Sanity$PartnerEmployeePromoForm,
  Sanity$PartnerEmployeePromoReferredForm,
} from '@otovo/shared/types/sanityTypes';
import Image from 'next/image';
import { useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import SectionWrapper from '../../../SectionWrapper';
import ConfirmationScreen from '../ConfirmationScreen';
import m from '../messages';
import partnerEmployeePromoMessages from './messages';
import PartnerEmployeePromoForm from './PartnerEmployeePromoForm';
import PartnerEmployeePromoReferredForm from './PartnerEmployeePromoReferredForm';

type Props = {
  form:
    | Sanity$PartnerEmployeePromoForm
    | Sanity$PartnerEmployeePromoReferredForm;
  is_referred: boolean;
};

const PartnerEmployeePromoFormSection = ({ form, is_referred }: Props) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorHasOccured, setErrorHasOccured] = useState(false);
  const intl = useIntl();

  const { formId } = form;

  const renderForm = () => {
    if (formSubmitted || errorHasOccured) {
      return (
        <ConfirmationScreen
          errorHasOccured={errorHasOccured}
          thankYouMessage={intl.formatMessage(m.thankYouMessage)}
          errorMessage={intl.formatMessage(m.errorHasOccured)}
        />
      );
    } else if (is_referred) {
      return (
        <PartnerEmployeePromoReferredForm
          formId={formId}
          updateFormSubmitted={setFormSubmitted}
          updateErrorHasOccured={setErrorHasOccured}
        />
      );
    } else {
      return (
        <PartnerEmployeePromoForm
          formId={formId}
          updateFormSubmitted={setFormSubmitted}
          updateErrorHasOccured={setErrorHasOccured}
        />
      );
    }
  };

  return (
    <SectionWrapper>
      <div className="col-span-6 flex flex-col items-center justify-center md:col-span-6 md:flex-row">
        <div className="h-full w-full">
          <h2 className="text-2xl font-medium">
            {is_referred ? (
              <T {...partnerEmployeePromoMessages.headingReferred} />
            ) : (
              <T {...partnerEmployeePromoMessages.heading} />
            )}
          </h2>
          <p className="mx-auto mb-6 text-base">
            {is_referred ? (
              <T {...partnerEmployeePromoMessages.descriptionReferred} />
            ) : (
              <T {...partnerEmployeePromoMessages.description} />
            )}
          </p>
          {renderForm()}
        </div>
      </div>
      <div className="col-span-2" />
      <div className="relative col-span-4 hidden h-auto md:block">
        <Image
          src="/img/formIllustration.png"
          alt="Talk to us"
          fill
          className="object-contain"
        />
      </div>
    </SectionWrapper>
  );
};

export default PartnerEmployeePromoFormSection;
