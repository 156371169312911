import { Cloud$Market } from '../../../types/cloudApi';
import { ERROR } from './errors';

type ReturnValue = {
  formatted: string;
  countryCode: string;
};

export default async function validatePhoneNumber(
  phoneNumber: string,
  market: Cloud$Market,
): Promise<ReturnValue> {
  let response;
  const url = `${process.env.NEXT_PUBLIC_OTOVOAPI_URL}/web/user_validation/validate_phone_number/`;
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
        country: market,
      }),
    });
  } catch {
    throw new Error(ERROR.UNABLE_TO_VALIDATE);
  }

  const json = await response.json();
  if (!response.ok) {
    if (json && Object.keys(json).length) {
      // Assuming that we're passing <market> correctly, the error is most
      // likely a validation error
      throw new Error(ERROR.INVALID_NUMBER);
    }
    throw new Error(ERROR.UNABLE_TO_VALIDATE);
  }

  return {
    formatted: json.formatted,
    countryCode: String(json.country_code),
  };
}
