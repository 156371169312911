import AnimatedCheck from '@otovo/shared/components/Icons/AnimatedCheck/AnimatedCheck';

type Props = {
  thankYouMessage: string;
  errorMessage: string;
  errorHasOccured: boolean;
};

const ConfirmationScreen = ({
  thankYouMessage,
  errorMessage,
  errorHasOccured,
}: Props) => {
  if (errorHasOccured) {
    return (
      <div className="rounded-lg bg-red-5 p-6">
        <p className="text-base font-semibold">{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center rounded-lg bg-green-5 p-6 md:flex-row">
      <AnimatedCheck success />
      <p className="p-4 font-semibold">{thankYouMessage}</p>
    </div>
  );
};

export default ConfirmationScreen;
