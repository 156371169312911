import { RadioGroup } from '@headlessui/react';
import { cn } from '@otovo/shared/lib/classNames';

type Props = {
  checked: boolean;
  label: string;
};

const RadioInput = ({ label, checked }: Props) => {
  return (
    <div
      className={cn(
        'flex cursor-pointer rounded border-2 bg-white p-2 transition-all',
        checked ? 'border-grey-40 bg-lilac-10' : 'border-grey-10 ',
      )}
    >
      <span
        className={cn(
          'mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full',
          checked ? 'border-[5px] border-grey-40' : 'border-1 bg-white',
        )}
        aria-hidden="true"
      >
        <span
          className={cn(checked ? 'h-1.5 w-1.5 rounded-full' : 'border-1')}
        />
      </span>
      <span className="ml-3 flex flex-col">
        <RadioGroup.Label
          as="span"
          className="block text-sm font-medium text-black"
        >
          {label}
        </RadioGroup.Label>
      </span>
    </div>
  );
};

export default RadioInput;
