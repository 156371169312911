import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'heatPumpsForm';

export default defineMessages({
  heading: {
    id: `${I18N_NAMESPACE}.heading`,
    defaultMessage: 'Interested? Let us know!',
  },
  description: {
    id: `${I18N_NAMESPACE}.description`,
    defaultMessage: `Fill in this form and one of our experts will reach out to you. Together, we optimize your home's energy efficiency with heat pumps`,
  },
  streetAddress: {
    id: `${I18N_NAMESPACE}.streetAddress`,
    defaultMessage: 'Street address',
  },
  streetAddressError: {
    id: `${I18N_NAMESPACE}.streetAddressError`,
    defaultMessage: 'Please enter your street address',
  },
  zipCode: {
    id: `${I18N_NAMESPACE}.zipCode`,
    defaultMessage: 'Zip code',
  },
  zipCodeError: {
    id: `${I18N_NAMESPACE}.zipCodeError`,
    defaultMessage: 'Zip code is required',
  },
  city: {
    id: `${I18N_NAMESPACE}.city`,
    defaultMessage: 'City',
  },
  cityError: {
    id: `${I18N_NAMESPACE}.cityError`,
    defaultMessage: 'City is required',
  },
  whereDoYouLive: {
    id: `${I18N_NAMESPACE}.whereDoYouLive`,
    defaultMessage: 'Where do you live?',
  },
  indipendentHouse: {
    id: `${I18N_NAMESPACE}.indipendentHouse`,
    defaultMessage: 'Indipendent House',
  },
  buildingWithLessThanFourRealEstateUnits: {
    id: `${I18N_NAMESPACE}.buildingWithLessThanFourRealEstateUnits`,
    defaultMessage: 'Building with less than four real estate units',
  },
  buildingWithMoreThanFourRealEstateUnits: {
    id: `${I18N_NAMESPACE}.buildingWithMoreThanFourRealEstateUnits`,
    defaultMessage: 'Building with more than four real estate units',
  },
});
