import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'CalculationPage.ContactMeModal';

export default defineMessages({
  leaveDetailsText: {
    id: `${I18N_NAMESPACE}.leaveDetailsText`,
    defaultMessage:
      'Leave us your details and we will gladly contact you to discuss the details of your offer and answer any questions you have.',
  },

  calcAcceptSuccessHelpText: {
    id: `${I18N_NAMESPACE}.calcAcceptSuccessHelpText`,
    defaultMessage: 'Super! We will contact you shortly.',
  },

  closeButton: {
    id: `${I18N_NAMESPACE}.closeButton`,
    defaultMessage: 'Close',
  },

  invalidEmailMessage: {
    id: `${I18N_NAMESPACE}.invalidEmailMessage`,
    defaultMessage: 'Invalid e-mail address',
  },

  invalidNameMessage: {
    id: `${I18N_NAMESPACE}.invalidNameMessage`,
    defaultMessage: 'Please enter both your first and last name.',
  },

  emptyNameMessage: {
    id: `${I18N_NAMESPACE}.emptyNameMessage`,
    defaultMessage: "Name can't be empty",
  },

  newsletterCheckboxLabel: {
    id: `${I18N_NAMESPACE}.newsletterCheckboxLabel`,
    defaultMessage: 'I want to subscribe to the newsletter',
  },

  newsletterSignupInfo: {
    id: `${I18N_NAMESPACE}.newsletterSignupInfo`,
    defaultMessage:
      'Learn more about solar energy, support schemes, product news and great offers.',
  },

  extendedConsentLabel: {
    id: `${I18N_NAMESPACE}.extendedConsentLabel`,
    defaultMessage: 'I want to receive solar information via XX',
  },

  extendedConsentDescription: {
    id: `${I18N_NAMESPACE}.extendedConsentDescription`,
    defaultMessage: 'Get notified when prices drop via XX',
  },

  callRecordedInfo: {
    id: `${I18N_NAMESPACE}.callRecordedInfo`,
    defaultMessage:
      'The call will be recorded for training purposes. For more information, please refer to the privacy policy.',
  },

  calcAcceptedHeading: {
    id: `${I18N_NAMESPACE}.calcAcceptedHeading`,
    defaultMessage: 'Request sucessfully received',
  },

  calcAcceptedNextSteps: {
    id: `${I18N_NAMESPACE}.calcAcceptedNextSteps`,
    defaultMessage:
      'A technician will do a remote inspection your roof and prepare an offer. We will get back to you shortly, usually within 24 hours.',
  },
});
