import cx from 'classnames';
import styles from './AnimatedCheck.module.scss';

type Props = {
  error?: boolean;
  success?: boolean;
  className?: string;
};

const AnimatedCheck = ({
  error = false,
  success = false,
  className = 'w-16',
}: Props) => {
  if (!error && !success) {
    return null;
  }

  return (
    <div className="p-2">
      {error ? (
        <svg
          viewBox="0 0 10 10"
          strokeWidth={2}
          stroke="#fff"
          strokeMiterlimit={10}
          className={cx(className, styles.checkmark, styles.error)}
        >
          <circle
            className={cx(styles.checkmark__circle, styles.error)}
            cx={25}
            cy={25}
            r={25}
          />
          <path
            className={cx(styles.checkmark__check, styles.slash)}
            d="M33.35 16.6l-16.7 16.8"
          />
          <path
            className={cx(styles.checkmark__check, styles.backslash)}
            d="M16.65 16.6l16.7 16.8"
          />
        </svg>
      ) : (
        <svg
          viewBox="0 0 50 50"
          strokeWidth={2}
          stroke="#fff"
          strokeMiterlimit={10}
          className={cx(styles.checkmark, className)}
        >
          <circle className={styles.checkmark__circle} cx={25} cy={25} r={25} />
          <path
            className={styles.checkmark__check}
            d="M13.1 26.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      )}
    </div>
  );
};

export default AnimatedCheck;
