import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'EmailSubscription';

export default defineMessages({
  invalidEmailMessage: {
    id: `${I18N_NAMESPACE}.invalidEmailMessage`,
    defaultMessage: 'Invalid e-mail address',
  },
});
