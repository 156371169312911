import { InputHTMLAttributes } from 'react';
import { cn } from '../../lib/classNames';
import Checkbox from './Checkbox';
import Label from './Label';

type CheckboxFieldProps = {
  label?: string | undefined;
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const CheckboxField = ({ label, className, ...props }: CheckboxFieldProps) => {
  return (
    <div className={cn('relative flex items-start', className)}>
      <div className="flex h-6 items-center">
        <Checkbox {...props} />
      </div>
      <div className="ml-3 text-sm leading-6">
        <Label htmlFor="comments" className="font-medium">
          {label}
        </Label>
      </div>
    </div>
  );
};

export default CheckboxField;
