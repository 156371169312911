import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'partnerEmployeePromoForm';

export default defineMessages({
  heading: {
    id: `${I18N_NAMESPACE}.heading`,
    defaultMessage: 'VW Group Mitarbeiter und Friends & Family Vorteil',
  },
  headingReferred: {
    id: `${I18N_NAMESPACE}.headingReferred`,
    defaultMessage: 'Vorteil einlösen',
  },
  description: {
    id: `${I18N_NAMESPACE}.description`,
    defaultMessage: `Bitte füllen Sie dieses Formular für sich oder Freunde & Familie aus, um sich den Vorteil zu sichern.`,
  },
  descriptionReferred: {
    id: `${I18N_NAMESPACE}.descriptionReferred`,
    defaultMessage: `Bitte füllen Sie dieses Formular aus, um das rabattierte Angebot zu erhalten.`,
  },
  streetAddress: {
    id: `${I18N_NAMESPACE}.streetAddress`,
    defaultMessage: 'Anschrift',
  },
  streetAddressError: {
    id: `${I18N_NAMESPACE}.streetAddressError`,
    defaultMessage: 'Anschrift erforderlich',
  },
  zipCode: {
    id: `${I18N_NAMESPACE}.zipCode`,
    defaultMessage: 'PLZ',
  },
  zipCodeError: {
    id: `${I18N_NAMESPACE}.zipCodeError`,
    defaultMessage: 'PLZ erforderlich',
  },
  city: {
    id: `${I18N_NAMESPACE}.city`,
    defaultMessage: 'Wohnort',
  },
  cityError: {
    id: `${I18N_NAMESPACE}.cityError`,
    defaultMessage: 'Wohnort erforderlich',
  },
  isThisAReferral: {
    id: `${I18N_NAMESPACE}.isThisAReferral`,
    defaultMessage: 'Für wen soll der Vorteil genutzt werden?',
  },
  forEmployee: {
    id: `${I18N_NAMESPACE}.forEmployee`,
    defaultMessage: 'Für mich, einen VW Group Mitarbeiter',
  },
  forFamilyFriend: {
    id: `${I18N_NAMESPACE}.forFamilyFriend`,
    defaultMessage: 'Für einen Freund oder Familie',
  },
  referralName: {
    id: `${I18N_NAMESPACE}.referralName`,
    defaultMessage: 'Name des Freundes oder Familienmitglieds',
  },
  referralEmail: {
    id: `${I18N_NAMESPACE}.referralEmail`,
    defaultMessage: 'E-Mail Adresse des Freundes oder Familienmitglieds',
  },
  email: {
    id: `${I18N_NAMESPACE}.email`,
    defaultMessage: 'E-Mail (nur E-Mail-Adressen des VW-Konzerns)',
  },
});
