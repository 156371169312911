import { InputHTMLAttributes } from 'react';
import CheckIcon from '../../components/Icons/CheckIcon/CheckIcon';
import { cn } from '../../lib/classNames';
import Label from './Label';

type InputFieldProps = {
  label?: string | undefined;
  errorMessage?: string;
  className?: string;
  showValidation?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const InputField = ({
  label,
  errorMessage,
  className,
  showValidation,
  ...props
}: InputFieldProps) => {
  return (
    <div>
      <Label htmlFor={props.id} className="block text-sm font-medium">
        {label}
      </Label>
      <div className="relative mt-2">
        <input
          {...props}
          className={cn(
            'm-0 mt-1 block min-h-[46px] w-full rounded-sm border-none px-6 py-3 text-sm font-medium shadow-sm outline-none ring-1 ring-grey-20 transition-all',
            'placeholder:text-muted',
            'focus:rounded-3xl focus:ring-2 focus:ring-blue-60',
            'hover:ring-2 hover:ring-blue-60',
            'disabled:bg-grey-10 disabled:shadow-none',
            className,
            { 'ring-red-60 focus:ring-red-60': !!errorMessage },
          )}
        />
        {!!errorMessage && (
          <p role="alert" className="mt-2 text-sm italic text-red-60">
            {errorMessage}
          </p>
        )}
        {showValidation && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <CheckIcon className="w-6 animate-appear text-green-40" />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
