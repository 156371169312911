import { forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import InputField from '../../../rainbow/Form/InputField';
import Label from '../../../rainbow/Form/Label';
import { Cloud$Market } from '../../../types/cloudApi';
import sharedMessages from '../../Translations/messages';
import MarketDropdownSelect from '../MarketDropdownSelect/MarketDropdownSelect';
import m from '../messages';
import { COUNTRY_CALLING_CODES } from './countryCallingCodes';
import { ERROR } from './errors';
import validatePhoneNumber from './validatePhoneNumberAPI';

export type ValidatedPhoneNumberImperativeActions = {
  setIsRequired: () => void;
  validatePhoneNumber: () => Promise<string | null>;
};

type Props = {
  defaultMarket: Cloud$Market;
};

type ValidationEnum = 'UNKNOWN' | 'VALID' | 'INVALID';

/**
 * ValidatedPhoneNumberInput
 *
 * Component that let you select a market and validate a phone number. Uses
 * Cloud's /web/user_validation/validate_phone_number/ API under the hood to do
 * validation
 */

const ValidatedPhoneNumberInput = ({ defaultMarket }: Props, ref) => {
  const [selectedMarket, setSelectedMarket] = useState(defaultMarket);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validationState, setValidationState] =
    useState<ValidationEnum>('UNKNOWN');
  const [errorMessage, setErrorMessage] = useState('');

  const intl = useIntl();

  const marketList = Object.keys(COUNTRY_CALLING_CODES) as Array<
    keyof typeof COUNTRY_CALLING_CODES
  >;

  const validateNumber = async () => {
    if (validationState === 'VALID') {
      // The phone number is valid and it hasn't changed since last time. Just
      // return the previous result
      return phoneNumber;
    }
    if (!phoneNumber || validationState === 'INVALID') {
      // To avoid spamming our servers, dont' try to validate it again.
      setErrorMessage(intl.formatMessage(m.phoneNumberRequired));
      return null;
    }

    try {
      const { formatted, countryCode } = await validatePhoneNumber(
        phoneNumber,
        selectedMarket,
      );

      setPhoneNumber(formatted);
      setValidationState('VALID');

      const marketDerivedFromNumber = marketList.find(
        (market) => Number(countryCode) === COUNTRY_CALLING_CODES[market],
      );

      if (marketDerivedFromNumber) {
        setSelectedMarket(marketDerivedFromNumber);
      }

      setErrorMessage('');
      return formatted;
    } catch (error) {
      setValidationState('INVALID');

      if (error.message === ERROR.INVALID_NUMBER) {
        setErrorMessage(intl.formatMessage(m.invalidPhoneNumber));
        return null;
      }
      setErrorMessage(intl.formatMessage(m.unableToValidate));
      return null;
    }
  };

  useImperativeHandle(ref, () => ({
    setIsRequired: () =>
      setErrorMessage(intl.formatMessage(m.phoneNumberRequired)),
    validatePhoneNumber: validateNumber,
  }));

  const handleMarketChange = (changedItem: Cloud$Market) => {
    setValidationState('UNKNOWN');
    setSelectedMarket(changedItem);
  };

  const handlePhoneNumberChange = (ev) => {
    const next = ev.currentTarget.value;
    setErrorMessage('');
    setValidationState('UNKNOWN');
    setPhoneNumber(next);
  };

  return (
    <div>
      <Label className="mb-1" htmlFor="phone">
        <T {...sharedMessages.phoneNumber} />
      </Label>

      <div className="relative flex flex-row">
        <div className="mr-1 mt-2 self-start">
          <MarketDropdownSelect
            onChange={handleMarketChange}
            items={marketList}
            selectedItem={selectedMarket}
            displayItem={(market) => `+${COUNTRY_CALLING_CODES[market]}`}
            itemToString={(market) => {
              return COUNTRY_CALLING_CODES[market].toString() || '';
            }}
          />
        </div>
        <div className="mb-3 flex-1">
          <InputField
            id="phone"
            onBlur={validateNumber}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            errorMessage={errorMessage}
            showValidation={validationState === 'VALID'}
            data-testid="contact-phone-number"
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ValidatedPhoneNumberInput);
