const CheckIcon = ({ ...rest }) => {
  return (
    <svg
      {...rest}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8" r="8" fill="currentColor" />
      <path
        d="M5.14526 8.40562L7.15817 10.8388L11.8549 5.16138"
        stroke="black"
        strokeOpacity={100}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CheckIcon;
