export function getBackgroundColorForDropdown(
  items: Array<any>,
  selectedItem: any,
  highlightedIndex: number,
  index: number,
): string {
  if (highlightedIndex === index) {
    return 'bg-blue-50';
  }
  if (items.indexOf(selectedItem) === index) {
    return 'bg-blue-40';
  }
  return '';
}
