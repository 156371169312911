import { cn } from '../../lib/classNames';

type Props = {
  className?: string;
  children: React.ReactNode;
  htmlFor: string;
};

const Label = ({ className, children, htmlFor }: Props) => {
  return (
    <label
      htmlFor={htmlFor}
      className={cn(
        'block cursor-pointer text-base font-medium text-lilac-100',
        className,
      )}
    >
      {children}
    </label>
  );
};

export default Label;
