import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { createValidationObject } from '@otovo/shared/components/createValidationObject';
import ValidatedPhoneNumberInput, {
  ValidatedPhoneNumberImperativeActions,
} from '@otovo/shared/components/Form/ValidatedPhoneNumberInput/ValidatedPhoneNumberInput';
import sharedMessages from '@otovo/shared/components/Translations/messages';
import { useRef, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { dataLayerPush } from '@otovo/shared/lib/analytics/gtm';
import InputField from '@otovo/shared/rainbow/Form/InputField';
import Button from '@otovo/shared/rainbow/Button/Button';
import {
  getRequestOptions,
  getSubmissionUrl,
  submitToHubspot,
} from '../HubspotSubmit';
import m from './messages';

const buildSubmissionFields = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  streetAddress,
  zipCode,
  city,
}) => {
  return [
    { name: 'email', value: email },
    { name: 'firstname', value: firstName },
    { name: 'lastname', value: lastName },
    { name: 'phone', value: phoneNumber },
    { name: 'address', value: streetAddress },
    { name: 'zip', value: zipCode },
    { name: 'city', value: city },
  ];
};

const splitName = (name: string) => {
  const firstName = name.split(' ').slice(0, -1).join(' ');
  const lastName = name.split(' ').slice(-1).join(' ');
  return { firstName, lastName };
};

type Props = {
  formId: string;
  updateFormSubmitted: (isFormSubmitted: boolean) => void;
  updateErrorHasOccured: (errorHasOccured: boolean) => void;
};

const PartnerEmployeePromoReferredForm = ({
  formId,
  updateFormSubmitted,
  updateErrorHasOccured,
}: Props) => {
  const intl = useIntl();
  const config = useGlobalConfig();

  const hubspotPortalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    city: '',
    zipCode: '',
  });

  const [isValidated, setIsValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');

  const phoneNumberRef: {
    current: null | ValidatedPhoneNumberImperativeActions;
  } = useRef(null);

  const isStreetAddressValid = streetAddress.length > 3;
  const isCityValid = city.length > 3;
  const isZipCodeValid = zipCode.length > 1;

  const handleTextChange = (e) => {
    setIsValidated(false);
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const formValidation = createValidationObject({
    formData,
    intl,
    config,
  });

  const submitForm = async (e) => {
    e.preventDefault();

    const phoneNumber = await phoneNumberRef.current?.validatePhoneNumber();
    if (!phoneNumber) phoneNumberRef.current?.setIsRequired();

    const isValidForm =
      formValidation.isClientValid &&
      phoneNumber &&
      isStreetAddressValid &&
      isCityValid &&
      isZipCodeValid;

    if (isValidForm) {
      const { firstName, lastName } = splitName(formData.name);
      const submissionFields = buildSubmissionFields({
        email: formData.email,
        firstName,
        lastName,
        phoneNumber: phoneNumber,
        streetAddress: streetAddress,
        zipCode: zipCode,
        city: city,
      });

      dataLayerPush({ event: 'Custom Hubspot Form Submission' });

      submitToHubspot({
        submissionURL: getSubmissionUrl({ hubspotPortalId, formId }),
        requestOptions: getRequestOptions(submissionFields),
        updateIsSubmitting: setIsSubmitting,
        updateErrorHasOccured,
        updateFormSubmitted,
      });
    }

    setIsValidated(true);
  };

  return (
    <div className="rounded-lg bg-lilac-5 p-6">
      <form onSubmit={submitForm} className="space-y-4">
        <InputField
          id="name"
          type="text"
          label={intl.formatMessage(sharedMessages.name)}
          value={formData.name}
          onChange={handleTextChange}
          errorMessage={isValidated && formValidation.name?.message}
          autoComplete="off"
        />

        <InputField
          id="email"
          type="email"
          label={intl.formatMessage(sharedMessages.email)}
          value={formData.email}
          onChange={handleTextChange}
          errorMessage={isValidated && formValidation.email?.message}
        />

        <ValidatedPhoneNumberInput
          ref={phoneNumberRef}
          defaultMarket={config.BU_CONFIG.market}
        />

        <InputField
          id="streetAddress"
          type="text"
          label={intl.formatMessage(m.streetAddress)}
          value={streetAddress}
          onChange={(e) => setStreetAddress(e.target.value)}
          errorMessage={
            isValidated &&
            !isStreetAddressValid &&
            intl.formatMessage(m.streetAddressError)
          }
        />
        <div className="md:flex md:space-x-6">
          <div className="w-full flex-1">
            <InputField
              className="mb-4 md:mb-0"
              id="city"
              type="text"
              label={intl.formatMessage(m.city)}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              errorMessage={
                isValidated && !isCityValid && intl.formatMessage(m.cityError)
              }
            />
          </div>
          <InputField
            className="md:w-28"
            id="zipCode"
            type="text"
            label={intl.formatMessage(m.zipCode)}
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            errorMessage={
              isValidated &&
              !isZipCodeValid &&
              intl.formatMessage(m.zipCodeError)
            }
          />
        </div>

        <Button
          type="submit"
          disabled={!formData.name || !formData.email}
          loading={isSubmitting}
          className="w-full"
        >
          <T {...sharedMessages.submitForm} />
        </Button>
      </form>
    </div>
  );
};

export default PartnerEmployeePromoReferredForm;
