import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'FormComponent';

export default defineMessages({
  emailPlaceholder: {
    id: `${I18N_NAMESPACE}.emailPlaceholder`,
    defaultMessage: 'Your e-mail address',
  },
  emailLabel: {
    id: `${I18N_NAMESPACE}.emailLabel`,
    defaultMessage: 'Email',
  },
  invalidPhoneNumber: {
    id: `${I18N_NAMESPACE}.invalidPhoneNumber`,
    defaultMessage: 'Invalid phone number',
  },
  phoneNumberRequired: {
    id: `${I18N_NAMESPACE}.phoneNumberRequired`,
    defaultMessage: 'Please specify your phone number',
  },
  unableToValidate: {
    id: `${I18N_NAMESPACE}.unableToValidate`,
    defaultMessage:
      'We could not validate your phone number, please try again.',
  },
  selectMarket: {
    id: `${I18N_NAMESPACE}.selectMarket`,
    defaultMessage: 'Select your market',
  },
});
