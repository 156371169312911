import { IntlShape } from 'react-intl';
import m from '../../../breeze/components/CalcPage/modals/ContactMeModal/messages';
import { Cloud$FormValidationErrors } from '../types/cloudApi';
import { Otovo$ConfigType } from '../types/otovoweb';
import { validateEmail } from './Form/InputFieldValidator';

type ValidationKeys = {
  _isClientInvalid: boolean;
  _isServerInvalid: any | void | boolean;
  message: string;
  state: string;
};

type ReturnType = {
  isClientValid: boolean;
  email: ValidationKeys;
  name: ValidationKeys;
};

type Args = {
  intl: IntlShape;
  formData: {
    name: string;
    email: string;
  };
  submitErrors?: Cloud$FormValidationErrors | null | undefined;
} & Otovo$ConfigType;

export const createValidationObject = ({
  formData,
  submitErrors,
  intl,
}: Args): ReturnType => {
  const isNameValid = formData.name.trim().split(' ').length >= 2;
  const isEmailValid = validateEmail(formData.email.trim());
  const isFormValid = isNameValid && isEmailValid;

  return {
    isClientValid: isFormValid,
    name: {
      _isClientInvalid: !isNameValid,
      _isServerInvalid: isFormValid && submitErrors?.name,
      get state() {
        if (this._isClientInvalid || this._isServerInvalid) {
          return 'error';
        }
        return '';
      },
      get message() {
        if (this._isClientInvalid) {
          return intl.formatMessage(m.invalidNameMessage);
        }
        if (this._isServerInvalid) {
          return intl.formatMessage(m.invalidNameMessage);
        }
        return '';
      },
    },
    email: {
      _isClientInvalid: !isEmailValid,
      _isServerInvalid: isFormValid && submitErrors?.email,
      get state() {
        if (this._isClientInvalid || this._isServerInvalid) {
          return 'error';
        }
        return '';
      },
      get message() {
        if (this._isClientInvalid || this._isServerInvalid) {
          return intl.formatMessage(m.invalidEmailMessage);
        }
        return '';
      },
    },
  };
};
