import { logSentryError } from '@otovo/shared/lib/sentry';

export const getSubmissionUrl = ({ hubspotPortalId, formId }) => {
  return `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${formId}`;
};

export const submitToHubspot = ({
  submissionURL,
  requestOptions,
  updateIsSubmitting,
  updateFormSubmitted,
  updateErrorHasOccured,
}: {
  submissionURL: string;
  requestOptions: object;
  updateIsSubmitting: (isSubmitted: boolean) => void;
  updateFormSubmitted: (isFormSubmitted: boolean) => void;
  updateErrorHasOccured: (errorHasOccured: boolean) => void;
}) => {
  updateIsSubmitting(true);
  fetch(submissionURL, requestOptions)
    .then((data) => {
      updateIsSubmitting(false);
      if (data.status === 200) {
        updateFormSubmitted(true);
      } else {
        updateErrorHasOccured(true);
        logSentryError(
          `Hubspot form is not properly configured. ${data.status} ${data.statusText} - ${data.url}`,
        );
      }
    })
    .catch((error) => {
      updateErrorHasOccured(true);
      updateIsSubmitting(false);
      logSentryError(error);
    });
};

type Field = {
  name: string;
  value: string | number | boolean;
};

export const getRequestOptions = (fields: Array<Field>) => {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      fields,
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: '',
          communications: [],
        },
        pageName: 'SanityLandingPage',
      },
    }),
  };
};
