import { Sanity$EmailSubscriptionForm } from '@otovo/shared/types/sanityTypes';
import cx from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';
import SectionWrapper from '../../../SectionWrapper';
import ConfirmationScreen from '../ConfirmationScreen';
import m from '../messages';
import EmailForm from './EmailForm';

type Props = {
  form: Sanity$EmailSubscriptionForm;
};

const EmailFormSection = ({ form }: Props) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [errorHasOccured, setErrorHasOccured] = useState(false);
  const intl = useIntl();
  const {
    formId,
    heading,
    label,
    description,
    submitButtonMessage,
    thankYouMessage,
    formDescription,
    image,
  } = form;

  return (
    <SectionWrapper>
      <div className="col-span-6">
        <h2
          className={cx(
            'text-2xl font-medium md:text-3xl',
            description ? 'mb-2' : 'mb-12',
          )}
        >
          {heading}
        </h2>
        {description && <p className="mx-auto mb-12">{description}</p>}
        {emailSubmitted || errorHasOccured ? (
          <ConfirmationScreen
            errorHasOccured={errorHasOccured}
            thankYouMessage={thankYouMessage}
            errorMessage={intl.formatMessage(m.errorHasOccured)}
          />
        ) : (
          <EmailForm
            formId={formId}
            label={label}
            submitButtonMessage={submitButtonMessage}
            formDescription={formDescription}
            updateEmailSubmitted={setEmailSubmitted}
            updateErrorHasOccured={setErrorHasOccured}
          />
        )}
      </div>
      <div className="col-span-4 col-start-8 hidden w-full md:block">
        <SanityImage sizes="400px" image={image} />
      </div>
    </SectionWrapper>
  );
};

export default EmailFormSection;
