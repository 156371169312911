import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { createValidationObject } from '@otovo/shared/components/createValidationObject';
import ValidatedPhoneNumberInput, {
  ValidatedPhoneNumberImperativeActions,
} from '@otovo/shared/components/Form/ValidatedPhoneNumberInput/ValidatedPhoneNumberInput';
import sharedMessages from '@otovo/shared/components/Translations/messages';
import { useRef, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import InputField from '@otovo/shared/rainbow/Form/InputField';
import CheckboxField from '@otovo/shared/rainbow/Form/CheckboxField';
import Button from '@otovo/shared/rainbow/Button/Button';
import {
  getRequestOptions,
  getSubmissionUrl,
  submitToHubspot,
} from '../HubspotSubmit';
import m from '../messages';

const buildSubmissionFields = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  hasPVSystem,
  pvSystemSize,
}) => {
  return [
    { name: 'email', value: email },
    { name: 'firstname', value: firstName },
    { name: 'lastname', value: lastName },
    { name: 'phone', value: phoneNumber },
    { name: 'has_pv_system', value: hasPVSystem },
    { name: 'pv_system_size', value: pvSystemSize },
  ];
};

const splitName = (name: string) => {
  const firstName = name.split(' ').slice(0, -1).join(' ');
  const lastName = name.split(' ').slice(-1).join(' ');
  return { firstName, lastName };
};

type Props = {
  formId: string;
  updateFormSubmitted: (isFormSubmitted: boolean) => void;
  updateErrorHasOccured: (errorHasOccured: boolean) => void;
};

const StandaloneBatteryForm = ({
  formId,
  updateFormSubmitted,
  updateErrorHasOccured,
}: Props) => {
  const intl = useIntl();
  const config = useGlobalConfig();

  const hubspotPortalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  const [isValidated, setIsValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [hasPVSystem, setHasPVSystem] = useState(false);
  const [pvSystemSize, setPVSystemSize] = useState(0);

  const phoneNumberRef: {
    current: null | ValidatedPhoneNumberImperativeActions;
  } = useRef(null);

  const handleTextChange = (e) => {
    setIsValidated(false);
    setFormData({ ...formData, [e.target.id]: e.currentTarget.value });
  };

  const formValidation = createValidationObject({
    formData,
    intl,
    config,
  });

  const submitForm = async (e) => {
    e.preventDefault();

    const phoneNumber = await phoneNumberRef.current?.validatePhoneNumber();
    if (!phoneNumber) {
      phoneNumberRef.current?.setIsRequired();
    }

    if (formValidation.isClientValid && phoneNumber) {
      const { firstName, lastName } = splitName(formData.name);

      const submissionsFields = buildSubmissionFields({
        email: formData.email,
        firstName,
        lastName,
        phoneNumber,
        hasPVSystem,
        pvSystemSize,
      });
      submitToHubspot({
        submissionURL: getSubmissionUrl({ hubspotPortalId, formId }),
        requestOptions: getRequestOptions(submissionsFields),
        updateIsSubmitting: setIsSubmitting,
        updateErrorHasOccured,
        updateFormSubmitted,
      });
    }
    setIsValidated(true);
  };

  return (
    <div className="rounded-lg bg-lilac-5 p-6">
      <form onSubmit={submitForm} className="space-y-4">
        <InputField
          type="text"
          id="name"
          label={intl.formatMessage(sharedMessages.name)}
          value={formData.name}
          onChange={handleTextChange}
          errorMessage={isValidated && formValidation.name.message}
        />

        <ValidatedPhoneNumberInput
          ref={phoneNumberRef}
          defaultMarket={config.BU_CONFIG.market}
        />

        <InputField
          id="email"
          type="email"
          label={intl.formatMessage(sharedMessages.email)}
          value={formData.email}
          onChange={handleTextChange}
          errorMessage={isValidated && formValidation.email.message}
        />
        <CheckboxField
          id="pv-system"
          label={intl.formatMessage(m.hasPVSystem)}
          checked={hasPVSystem}
          onChange={(e) => setHasPVSystem(e.target.checked)}
        />

        {hasPVSystem && (
          <InputField
            type="number"
            id="pv-system-size"
            inputMode="numeric"
            label={intl.formatMessage(m.howBigIsPVSystem)}
            value={pvSystemSize}
            onChange={(e) => setPVSystemSize(parseInt(e.target.value, 10))}
          />
        )}

        <Button
          type="submit"
          className="mt-6 w-full"
          disabled={!formData.name && !formData.email}
          loading={isSubmitting}
        >
          <T {...sharedMessages.submitForm} />
        </Button>
      </form>
    </div>
  );
};

export default StandaloneBatteryForm;
