import { InputHTMLAttributes } from 'react';
import { cn } from '../../lib/classNames';

type CheckboxProps = {
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({ className, ...props }: CheckboxProps) => {
  return (
    <input
      className={cn(
        'h-5 w-5 border-grey-30 text-green-40 ring-0',
        'hover:border-grey-90',
        'ring-inset focus:border-grey-90 focus:outline-none focus:ring-0',
        'checked:border-none',
        className,
      )}
      {...props}
      type="checkbox"
    />
  );
};

export default Checkbox;
