import { Sanity$TextBlock } from '@otovo/shared/types/sanityTypes';
import { PortableText } from '@portabletext/react';
import { validateEmail } from '@otovo/shared/components/Form/InputFieldValidator';
import sharedMessages from '@otovo/shared/components/Translations/messages';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import InputField from '@otovo/shared/rainbow/Form/InputField';
import Button from '@otovo/shared/rainbow/Button/Button';
import SanityPortableTextComponents from '@otovo/shared/components/Sanity/SanityPortableTextComponents';
import {
  getRequestOptions,
  getSubmissionUrl,
  submitToHubspot,
} from '../HubspotSubmit';

import m from './messages';

const buildSubmissionFields = ({ email }) => {
  return [{ name: 'email', value: email }];
};

type Props = {
  formId: string;
  label: string;
  submitButtonMessage: string;
  formDescription: Array<Sanity$TextBlock>;
  updateEmailSubmitted: (isEmailSubmitted: boolean) => void;
  updateErrorHasOccured: (errorHasOccured: boolean) => void;
};

const EmailForm = ({
  formId,
  label,
  submitButtonMessage,
  formDescription,
  updateEmailSubmitted,
  updateErrorHasOccured,
}: Props) => {
  const intl = useIntl();
  const hubspotPortalId = process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID;

  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTextChange = (e) => {
    setEmail(e.currentTarget.value.trim());
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (validateEmail(email)) {
      const submissionsFields = buildSubmissionFields({ email });
      submitToHubspot({
        submissionURL: getSubmissionUrl({ hubspotPortalId, formId }),
        requestOptions: getRequestOptions(submissionsFields),
        updateIsSubmitting: setIsSubmitting,
        updateErrorHasOccured,
        updateFormSubmitted: updateEmailSubmitted,
      });
    } else {
      setErrorMessage(intl.formatMessage(m.invalidEmailMessage));
    }
  };

  return (
    <div className="rounded-lg bg-lilac-5 p-6">
      <form onSubmit={submitForm} className="space-y-4">
        <InputField
          id="email"
          label={label}
          type="email"
          placeholder={intl.formatMessage(sharedMessages.email)}
          onChange={handleTextChange}
          errorMessage={errorMessage}
        />
        <Button type="submit" loading={isSubmitting} disabled={!email}>
          {submitButtonMessage}
        </Button>
        <div>
          <PortableText
            value={formDescription}
            components={SanityPortableTextComponents}
          />
        </div>
      </form>
    </div>
  );
};

export default EmailForm;
