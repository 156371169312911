import {
  Sanity$EmailSubscriptionForm,
  Sanity$HeatPumpForm,
  Sanity$StandaloneBatteryForm,
  Sanity$PartnerEmployeePromoForm,
  Sanity$PartnerEmployeePromoReferredForm,
} from '@otovo/shared/types/sanityTypes';

import EmailFormSection from './EmailSubscription/EmailFormSection';
import StandaloneBatteryFormSection from './StandaloneBattery/StandaloneBatteryFormSection';
import HeatPumpsFormSection from './HeatPumps/HeatPumpsFormSection';
import PartnerEmployeePromoFormSection from './PartnerEmployeePromo/PartnerEmployeePromoFormSection';

type Props = {
  form:
    | Sanity$EmailSubscriptionForm
    | Sanity$HeatPumpForm
    | Sanity$StandaloneBatteryForm
    | Sanity$PartnerEmployeePromoForm
    | Sanity$PartnerEmployeePromoReferredForm;
};

const HubspotFormSection = ({ form }: Props) => {
  const { _type: formType } = form;

  let FormComponent;

  switch (formType) {
    case 'emailSubscription':
      FormComponent = <EmailFormSection form={form} />;
      break;
    case 'standaloneBattery':
      FormComponent = <StandaloneBatteryFormSection form={form} />;
      break;
    case 'heatPumps':
      FormComponent = <HeatPumpsFormSection form={form} />;
      break;
    case 'partnerEmployeePromo':
      FormComponent = (
        <PartnerEmployeePromoFormSection is_referred={false} form={form} />
      );
      break;
    case 'partnerEmployeePromoReferred':
      FormComponent = (
        <PartnerEmployeePromoFormSection is_referred={true} form={form} />
      );
      break;
    default:
      return null;
  }

  return <div id={formType}>{FormComponent}</div>;
};

export default HubspotFormSection;
